import React, { useState, useEffect } from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import useAccount from './hooks/useAccount';
import useChainId from './hooks/useChainId';
import { connectToWallet } from './hooks/operations';
import { Box } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
    root: {
      color: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
});
function App() {
  const changeAccout = useAccount();
  const chainId = useChainId();
  const [accountAdd, setAccount] = useState(changeAccout);

  const [selectedChain, setselectedChain] = React.useState('Matic');

  const connect = async () => {
    if (window.ethereum) {
      const res = await connectToWallet();
      setAccount(res);
    } else {
      alert('Please Install Metamask');
    }
  };

  useEffect(() => {
    setAccount(changeAccout);
  }, [changeAccout]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{ backgroundColor: '#212529', height: '100vh', width: '100%' }}
      >
        <Header
          account={accountAdd}
          connect={connect}
          selectedChain={selectedChain}
          setselectedChainFun={(data) => {
            setselectedChain(data);
          }}
        />
        <Home
          chainId={chainId}
          account={accountAdd}
          selectedChain={selectedChain}
        />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
