import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Box, Divider, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EthLogo from '../../images/ethman.png';
import MaticLogo from '../../images/matic.png';

import BinanceLogo from '../../images/binance.png';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import Web3 from 'web3';
import axios from 'axios';
import ButtonCircularProgress from '../../components/ButtonCircularProgress';
import { ethers } from 'ethers';
import {
  BSCswap,
  ETHswap,
  BSCSwapAgentABIData,
  EthSwapAgentABIData,
  IBEP20ABIData,
  IMATIC20ABIData,
  MaticTokenAddress,
  MATICSwapAgentABIData,
  Maticswap,
  BinanceTokenAddress,
  EthereumChainId,
  MaticChainId,
  BinanceChainId,
  ETHTokenAddress,
  BEP20IMPLABIData,
  binanceNode,
  ethNode,
} from '../../Utils/index';
import {
  getBinanceBalance,
  getEthereumBalance,
  sortAddress,
  getTotalSupply,
  getMaticBalance,
} from '../../hooks/operations';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import { fillBSC2ETHSwapFun } from '../../Utils/fillBSC2ETHSwap';
// import { fillETH2BSCSwapFun } from '../../Utils/fillETH2BSCSwap';
// import { fillETH2MATICwapFun } from '../../Utils/fillETH2MATICSwap';
// import { fillMATIC2ETHSwapFun } from '../../Utils/fillMATIC2ETHSwap';
const useStyles = makeStyles((theme) => ({
  containerLeft: {
    borderRight: '1px solid #90c7c6',
    position: 'relative',
  },
  root: {
    background: '#2789f5',
    color: 'white',
    '&$disabled': {
      backgroundColor: '#4197f7',
      color: 'white',
      opacity: 0.6,
      cursor: 'not-allowed',
    },
    '&:hover': {
      background: '#0079ff',
      color: 'white',
    },
  },
  disabled: {},
  whiteText: {
    color: '#fff',
  },
  arrowIcon: {
    position: 'absolute',
    top: '70px',
    right: '-38px',
    fontSize: '46px',
    borderRadius: '50%',
    padding: '15px',
    backgroundColor: '#212529',
  },
  caption: {
    color: '#fff',
    fontSize: ' 17px',
    fontWeight: '400',
    lineHeight: '1.66',
  },

  itemBox: {
    padding: '20px 25px',
  },

  dialogueContent: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },

  links: {
    width: '20px',
    paddingLeft: '12px',
  },

  curvedDialogue: {
    borderRadius: '22px',
  },

  mainContainer: {
    backgroundColor: '#161c24',
    padding: '2%',
    borderRadius: '12px',
    color: 'white',
  },

  transferButton: {
    height: '60px',
    fontSize: '18px',
    minWidth: '235px',
    borderRadius: '8px',
    backgroundColor: '#077dff',
    color: 'white',
    margin: 3,

    '&$disabled': {
      backgroundColor: '#4197f7',
      color: 'white',
      opacity: 0.6,
      cursor: 'not-allowed',
    },
    '&:hover': {
      background: '#0079ff',
      color: 'white',
    },
  },

  textField: {
    fontSize: '33px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Home = ({ chainId, account, selectedChain }) => {
  const classes = useStyles();
  const [openEthereum, setEthereum] = useState(false);
  const [openBinance, setBinance] = useState(false);
  const [openMatic, setMatic] = useState(false);
  const [tokenBalanceBinance, setTokenBalanceBinance] = useState('');
  const [totlaSupplyBinance, setTotlaSupplyBinance] = useState(0);
  const [tokenBalanceEthereum, setTokenBalanceEthereum] = useState('');
  const [totalSupplyEthereum, setTotalSupplyEthereum] = useState(0);
  const [transferAmount, setTransferAmount] = useState('');
  const [isUpdatingTransfer, setIsUpdatingTransfer] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [misMatchArraw, setMisMatchArraw] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorMSG, setErrorMSG] = useState('');
  const [isError, setIsError] = useState(false);
  const [currentChain, setCurrentChain] = useState('');
  const [tokenBalanceMatic, setTokenBalanceMatic] = useState();

  const toogleArrawHandler = () => {
    setTransferAmount('');
    let NewcurrentChain = currentChain;
    if (selectedChain === 'Matic') {
      NewcurrentChain =
        currentChain === MaticChainId ? EthereumChainId : MaticChainId;
    } else {
      NewcurrentChain =
        currentChain === BinanceChainId ? EthereumChainId : BinanceChainId;
    }

    setCurrentChain(NewcurrentChain);
    // currentChain === BinanceChainId
    //   ? EthereumChainId
    //   : currentChain === MaticChainId
    //   ? BinanceChainId
    //   : MaticChainId;
    // if (currentChain === BinanceChainId || currentChain === MaticChainId) {
    //   setCurrentChain(NewcurrentChain);
    // } else {
    //   setCurrentChain(NewcurrentChain);
    // }
    console.log('currentChain', currentChain);
    console.log('parseInt(chainId)', parseInt(chainId));
    console.log('NewcurrentChain(NewcurrentChain)', parseInt(NewcurrentChain));

    if (selectedChain === 'Matic') {
      if (
        parseInt(chainId) === EthereumChainId ||
        parseInt(chainId) === MaticChainId
      ) {
        if (parseInt(chainId) === NewcurrentChain) {
          setMisMatchArraw(false);
        } else {
          setMisMatchArraw(true);
        }
      } else {
        setMisMatchArraw(true);
      }
    } else {
      if (
        parseInt(chainId) === EthereumChainId ||
        parseInt(chainId) === BinanceChainId
      ) {
        if (parseInt(chainId) === NewcurrentChain) {
          setMisMatchArraw(false);
        } else {
          setMisMatchArraw(true);
        }
      } else {
        setMisMatchArraw(true);
      }
    }
  };

  useEffect(() => {
    if (chainId !== null) {
      setTransferAmount('');

      console.log('selectedChain', selectedChain);
      console.log('currentChain', currentChain);
      console.log('parseInt(chainId)', parseInt(chainId));
      setCurrentChain(parseInt(chainId));
      if (selectedChain === 'Matic') {
        if (
          parseInt(chainId) === EthereumChainId ||
          parseInt(chainId) === MaticChainId
        ) {
          setCurrentChain(parseInt(chainId));
          if (parseInt(chainId) === currentChain) {
            setMisMatchArraw(false);
          }
        } else {
          setMisMatchArraw(true);
        }
      } else {
        if (
          parseInt(chainId) === EthereumChainId ||
          parseInt(chainId) === BinanceChainId
        ) {
          setCurrentChain(parseInt(chainId));
          if (parseInt(chainId) === currentChain) {
            setMisMatchArraw(false);
          }
        } else {
          setMisMatchArraw(true);
        }
      }
    }
  }, [chainId, selectedChain]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setMisMatchArraw(false);
    setIsError(false);
    setErrorMSG('');
  };

  const transferHandlerBNB = async () => {
    console.log('swapETH2BSC');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(BSCSwapAgentABIData, BSCswap);
        contract.methods
          .swapETH2BSC(
            ETHTokenAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: '50000' })
          .then(async function (receipt) {
            if (receipt.status) {
              setOpen(true);
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                'fillETH2BSCSwapFun'
              );
              setIsApprove(false);
              setOpen(false);
              setTransferAmount('');
              getBalanceFun(account);
              setIsUpdatingTransfer(false);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .catch((err) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.log('ERROR', err);
          });
      } catch (err) {
        console.log('err', err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const approveTokenBNB = async () => {
    console.log('swapETH2BSC');
    setIsError(false);
    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = new web3.eth.Contract(
          BEP20IMPLABIData,
          ETHTokenAddress
        );

        const transactionParameters = {
          to: ETHTokenAddress,
          from: account,
          data: contract.methods
            .approve(BSCswap, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on('transactionHash', (hash) => {})
          .once('confirmation', (confirmationNumber, receipt) => {
            setIsUpdatingTransfer(false);
            if (receipt.status) {
              console.log('receipt', receipt);
              setIsApprove(true);
            } else {
            }
          })
          .on('error', (error) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.error('error', error);
          });
      } catch (err) {
        console.error('ERROR: '.err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const transferHandler = async () => {
    console.log('swapBSC2ETH');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(EthSwapAgentABIData, ETHswap);
        contract.methods
          .swapBSC2ETH(
            BinanceTokenAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: '50000' })
          .then(async function (receipt) {
            if (receipt.status) {
              setOpen(true);
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                'fillBSC2ETHSwapFun'
              );
              setIsApprove(false);
              setOpen(false);
              setTransferAmount('');
              getBalanceFun(account);
              setIsUpdatingTransfer(false);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .catch((err) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.log('ERROR', err);
          });
      } catch (err) {
        console.log('err', err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const approveToken = async () => {
    console.log('swapBSC2ETH');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = new web3.eth.Contract(
          IBEP20ABIData,
          BinanceTokenAddress
        );
        const transactionParameters = {
          to: BinanceTokenAddress,
          from: account,
          data: contract.methods
            .approve(ETHswap, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on('transactionHash', (hash) => {})
          .once('confirmation', (confirmationNumber, receipt) => {
            if (receipt.status) {
              setIsUpdatingTransfer(false);
              setIsApprove(true);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .on('error', (error) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.error('error', error);
          });
      } catch (err) {
        console.error('ERROR: '.err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const transferHandlerETHtoMatic = async () => {
    console.log('transferHandlerETHtoMatic');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(BSCSwapAgentABIData, BSCswap);
        contract.methods
          .swapETH2BSC(
            ETHTokenAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: '50000' })
          .then(async function (receipt) {
            if (receipt.status) {
              console.log('receipt', receipt);

              setOpen(true);
              // await fillETH2MATICwapFun(
              //   receipt.transactionHash,
              //   account,
              //   ethers.utils.parseEther(transferAmount).toString()
              //   // 'fillBSC2ETHSwapFun'
              // );
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                'fillETH2MATICSwapFun'
              );
              setIsApprove(false);
              setOpen(false);
              setTransferAmount('');
              getBalanceFun(account);
              setIsUpdatingTransfer(false);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .catch((err) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.log('ERROR', err);
          });
      } catch (err) {
        console.log('err', err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const approveTokenETHtoMatic = async () => {
    console.log('approveTokenETHtoMatic');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = new web3.eth.Contract(
          BEP20IMPLABIData,
          ETHTokenAddress
        );
        const transactionParameters = {
          to: ETHTokenAddress,
          from: account,
          data: contract.methods
            .approve(BSCswap, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on('transactionHash', (hash) => {})
          .once('confirmation', (confirmationNumber, receipt) => {
            if (receipt.status) {
              setIsUpdatingTransfer(false);
              setIsApprove(true);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .on('error', (error) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.error('error', error);
          });
      } catch (err) {
        console.error('ERROR: '.err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const approveTokenMatictoETH = async () => {
    console.log('approveTokenMatictoETH');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = new web3.eth.Contract(
          IMATIC20ABIData,
          MaticTokenAddress
        );
        const transactionParameters = {
          to: MaticTokenAddress,
          from: account,
          data: contract.methods
            .approve(Maticswap, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on('transactionHash', (hash) => {})
          .once('confirmation', (confirmationNumber, receipt) => {
            if (receipt.status) {
              setIsUpdatingTransfer(false);
              setIsApprove(true);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .on('error', (error) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.error('error', error);
          });
      } catch (err) {
        console.error('ERROR: '.err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const transferHandlerMatictoETH = async () => {
    console.log('transferHandlerMatictoETH');

    if (transferAmount !== '' && transferAmount > 0) {
      try {
        setIsUpdatingTransfer(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(
          MATICSwapAgentABIData,
          Maticswap
        );
        contract.methods
          .swapBSC2ETH(
            MaticTokenAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: '50000' })
          .then(async function (receipt) {
            if (receipt.status) {
              console.log('receipt', receipt);
              setOpen(true);
              // await fillMATIC2ETHSwapFun(
              //   receipt.transactionHash,
              //   account,
              //   ethers.utils.parseEther(transferAmount).toString()
              //   // 'fillBSC2ETHSwapFun'
              // );
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                'fillMATIC2ETHSwapFun'
              );
              setIsApprove(false);
              setOpen(false);
              setTransferAmount('');
              getBalanceFun(account);
              setIsUpdatingTransfer(false);
            } else {
              setIsUpdatingTransfer(false);
            }
          })
          .catch((err) => {
            setErrorMSG('Failed');
            setIsError(true);
            setIsUpdatingTransfer(false);
            console.log('ERROR', err);
          });
      } catch (err) {
        console.log('err', err);
        setIsUpdatingTransfer(false);
      }
    }
  };

  const fillSwapFun = async (hash, userAddress, tokenAmount, endPoint) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/swapper/${endPoint}`,
        // `http://localhost:1826/api/v1/swapper/${endPoint}`,
        {
          hash: hash,
          userAddress: userAddress,
          tokenAmount: tokenAmount,
        }
      );
      if (res.data.responseCode) {
        return true;
      }
    } catch (err) {
      console.log('ERROR', err);
      return false;
    }
  };

  useEffect(() => {
    setTokenBalanceBinance();
    setTokenBalanceEthereum(0);
    setTotalSupplyEthereum(0);
    setTotlaSupplyBinance(0);
    if (account) {
      getBalanceFun(account);
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBalanceFun = async (account) => {
    console.log('ddddddddddddd');
    setTokenBalanceBinance(await getBinanceBalance(account));
    setTokenBalanceEthereum(await getEthereumBalance(account));
    setTokenBalanceMatic(await getMaticBalance(account));
    setTotalSupplyEthereum(
      await getTotalSupply(IBEP20ABIData, ETHTokenAddress, account, ethNode)
    );
    setTotlaSupplyBinance(
      await getTotalSupply(
        BEP20IMPLABIData,
        BinanceTokenAddress,
        account,
        binanceNode
      )
    );
  };

  return (
    <>
      <Grid container direction="column">
        <>
          <Container maxWidth="md">
            <Snackbar
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity="info">
                Your tokens will be bridged to{' '}
                {selectedChain !== 'Matic'
                  ? currentChain === BinanceChainId
                    ? 'ETH'
                    : 'BSC'
                  : currentChain === MaticChainId
                  ? 'ETH'
                  : 'MATIC'}{' '}
                shortly
              </Alert>
            </Snackbar>

            <Snackbar
              open={misMatchArraw || isError}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity="error">
                {isError ? errorMSG : 'Switch Network'}
              </Alert>
            </Snackbar>

            <Box my={4}>
              <Box p={1} justifyItems="center" alignItems="center">
                <Box
                  style={{
                    border: '2px solid #161c24',
                    backgroundColor: '#161c24',
                    color: 'white',
                    borderRadius: 90,
                    width: '60%',
                    marginLeft: '20%',
                    paddingTop: 10,
                    paddingBottom: 10,
                    marginBottom: 10,
                  }}
                >
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {selectedChain !== 'Matic'
                      ? currentChain === EthereumChainId
                        ? 'ETH to BSC  Bridge'
                        : 'BSC to ETH Bridge'
                      : currentChain === EthereumChainId
                      ? 'ETH to MATIC  Bridge'
                      : 'MATIC to ETH Bridge'}
                  </Typography>
                </Box>
              </Box>
              <Grid container className={classes.mainContainer}>
                <Grid item xs={6} className={classes.containerLeft}>
                  <Box p={4}>
                    <Typography
                      variant="caption"
                      className={classes.caption}
                      paragraph
                    >
                      Ethereum Mainnet
                    </Typography>

                    <Typography
                      variant="h5"
                      className={classes.whiteText}
                      paragraph
                    >
                      DSCVR ERC20
                    </Typography>
                    <Typography variant="h5">
                      {tokenBalanceEthereum ? tokenBalanceEthereum : '0'}{' '}
                    </Typography>
                    <Box mt={2}>
                      <Button
                        // className={classes.whiteText}
                        disabled={tokenBalanceBinance ? false : true}
                        classes={{
                          root: classes.root, // class name, e.g. `root-x`
                          disabled: classes.disabled, // class name, e.g. `disabled-x`
                        }}
                        size="small"
                        onClick={() => setEthereum(true)}
                      >
                        Show more
                      </Button>
                    </Box>
                    {currentChain === EthereumChainId ? (
                      <ArrowForwardIcon
                        className={classes.arrowIcon}
                        onClick={() => toogleArrawHandler()}
                      />
                    ) : (
                      <ArrowBackIcon
                        className={classes.arrowIcon}
                        onClick={() => toogleArrawHandler()}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={6} className={classes.containerRight}>
                  <Box pt={4} pl={7}>
                    <Typography
                      variant="caption"
                      className={classes.caption}
                      paragraph
                    >
                      {selectedChain === 'Matic'
                        ? 'Matic'
                        : 'Binance Smart Chain'}
                    </Typography>

                    <Typography
                      variant="h5"
                      className={classes.whiteText}
                      paragraph
                    >
                      {selectedChain === 'Matic'
                        ? 'DSCVR MATIC20'
                        : 'DSCVR BEP20'}
                    </Typography>
                    {selectedChain === 'Matic' ? (
                      <Typography variant="h5">
                        {tokenBalanceMatic
                          ? tokenBalanceMatic.maticBalance
                          : '0'}{' '}
                      </Typography>
                    ) : (
                      <Typography variant="h5">
                        {tokenBalanceBinance
                          ? tokenBalanceBinance.binanceBalance
                          : '0'}{' '}
                      </Typography>
                    )}

                    {/* MATIC */}

                    <Box mt={2}>
                      <Button
                        classes={{
                          root: classes.root, // class name, e.g. `root-x`
                          disabled: classes.disabled, // class name, e.g. `disabled-x`
                        }}
                        size="small"
                        onClick={() =>
                          selectedChain === 'Matic'
                            ? setMatic(true)
                            : setBinance(true)
                        }
                        disabled={tokenBalanceEthereum ? false : true}
                      >
                        Show more
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Container maxWidth="md">
            <Grid
              container
              className={classes.mainContainer}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item style={{ paddingRight: 34 }}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography variant="h5" className={classes.caption}>
                      Transfer Amount
                    </Typography>
                  </Grid>
                  <Grid item>
                    {/* <Button
                      classes={{
                        root: classes.root, // class name, e.g. `root-x`
                        disabled: classes.disabled, // class name, e.g. `disabled-x`
                      }}
                      disabled={tokenBalanceBinance ? false : true}
                      onClick={() =>
                        currentChain === BinanceChainId
                          ? setTransferAmount(
                              tokenBalanceBinance.binanceBalance
                            )
                          : setTransferAmount(tokenBalanceEthereum)
                      }
                    >
                      Max
                    </Button> */}
                  </Grid>
                </Grid>
                <Grid item>
                  <Box mt={2}>
                    <TextField
                      InputProps={{
                        style: { color: '#fff' },
                      }}
                      placeholder="Enter amount to be transferred"
                      inputProps={{ style: { fontSize: 29 } }}
                      fullWidth={true}
                      className="transfer-input"
                      value={transferAmount}
                      onChange={(e) => setTransferAmount(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid item> */}
              {selectedChain === 'Matic' ? (
                <Box height="100%" display="flex" alignItems="center" mt={5}>
                  <Button
                    // variant="contained"
                    // className={classes.transferButton}
                    classes={{
                      root: classes.transferButton,
                      disabled: classes.disabled,
                    }}
                    disableRipple
                    disabled={
                      isUpdatingTransfer ||
                      isApprove ||
                      misMatchArraw ||
                      account === null
                    }
                    onClick={
                      currentChain === MaticChainId
                        ? approveTokenMatictoETH
                        : approveTokenETHtoMatic
                    }
                  >
                    Approve
                    {isUpdatingTransfer && !isApprove && (
                      <ButtonCircularProgress />
                    )}
                  </Button>
                  <Button
                    classes={{
                      root: classes.transferButton,
                      disabled: classes.disabled,
                    }}
                    endIcon={<ArrowForwardIcon />}
                    disableRipple
                    disabled={isUpdatingTransfer || !isApprove || misMatchArraw}
                    onClick={
                      currentChain === MaticChainId
                        ? transferHandlerMatictoETH
                        : transferHandlerETHtoMatic
                    }
                  >
                    Transfer
                    {isUpdatingTransfer && isApprove && (
                      <ButtonCircularProgress />
                    )}
                  </Button>
                </Box>
              ) : (
                <Box height="100%" display="flex" alignItems="center" mt={5}>
                  <Button
                    // variant="contained"
                    // className={classes.transferButton}
                    classes={{
                      root: classes.transferButton,
                      disabled: classes.disabled,
                    }}
                    disableRipple
                    disabled={
                      isUpdatingTransfer ||
                      isApprove ||
                      misMatchArraw ||
                      account === null
                    }
                    onClick={
                      currentChain === BinanceChainId
                        ? approveToken
                        : approveTokenBNB
                    }
                  >
                    Approve
                    {isUpdatingTransfer && !isApprove && (
                      <ButtonCircularProgress />
                    )}
                  </Button>
                  <Button
                    classes={{
                      root: classes.transferButton,
                      disabled: classes.disabled,
                    }}
                    endIcon={<ArrowForwardIcon />}
                    disableRipple
                    disabled={isUpdatingTransfer || !isApprove || misMatchArraw}
                    onClick={
                      currentChain === BinanceChainId
                        ? transferHandler
                        : transferHandlerBNB
                    }
                  >
                    Transfer
                    {isUpdatingTransfer && isApprove && (
                      <ButtonCircularProgress />
                    )}
                  </Button>
                </Box>
              )}
              {/* </Grid> */}
            </Grid>
          </Container>

          <Dialog
            open={openMatic}
            onClose={() => setMatic(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            PaperProps={{ style: { borderRadius: 23 } }}
          >
            <Box display="flex" p={2} pl={2} alignItems="center">
              <img src={MaticLogo} alt="eth mannet" width="43px" />
              <Typography variant="h6">Matic</Typography>
            </Box>

            <DialogContent className={classes.dialogueContent}>
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Bridge Address</Typography>
                <Box display="flex">
                  <Typography variant="body1">
                    {sortAddress(Maticswap)}
                  </Typography>
                  <Link
                    href={`https://explorer-mumbai.maticvigil.com/address/${Maticswap}`}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#3220ac"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                      className={`${classes.links} jss226`}
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>

              <Divider light={true} />
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Token Address</Typography>

                <Box display="flex">
                  <Typography variant="body1">
                    {sortAddress(MaticTokenAddress)}
                  </Typography>
                  <Link
                    href={`https://explorer-mumbai.maticvigil.com/address/${MaticTokenAddress}`}
                    className={classes.links}
                    target="_blank"
                  >
                    <svg
                      className={`${classes.links} jss226`}
                      // class="jss226"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#3220ac"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>
              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Total Token Supply</Typography>
                <Typography variant="body1" style={{ paddingRight: '32px' }}>
                  {totalSupplyEthereum}
                </Typography>
              </Box>
              <Divider light={true} />

              {/* <Divider light={true} /> */}

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Your DSCVR Balance</Typography>
                <Box display="flex">
                  <Typography variant="body1" style={{ paddingRight: '32px' }}>
                    {tokenBalanceMatic && tokenBalanceMatic.maticBalance}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            open={openEthereum}
            onClose={() => setEthereum(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            PaperProps={{ style: { borderRadius: 23 } }}
          >
            <Box display="flex" p={2} pl={2} alignItems="center">
              <img src={EthLogo} alt="eth mannet" width="43px" />
              <Typography variant="h6">Ethereum Mainnet</Typography>
            </Box>

            <DialogContent className={classes.dialogueContent}>
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Bridge Address</Typography>
                <Box display="flex">
                  <Typography variant="body1">
                    {sortAddress(BSCswap)}
                  </Typography>
                  <Link
                    href={`https://bscscan.com/address/${BSCswap}`}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#3220ac"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                      className={`${classes.links} jss226`}
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>

              <Divider light={true} />
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Token Address</Typography>

                <Box display="flex">
                  <Typography variant="body1">
                    {sortAddress(ETHTokenAddress)}
                  </Typography>
                  <Link
                    href={`https://etherscan.io/address/${ETHTokenAddress}`}
                    className={classes.links}
                    target="_blank"
                  >
                    <svg
                      className={`${classes.links} jss226`}
                      // class="jss226"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#3220ac"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>
              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Total Token Supply</Typography>
                <Typography variant="body1" style={{ paddingRight: '32px' }}>
                  {totalSupplyEthereum}
                </Typography>
              </Box>
              <Divider light={true} />

              {/* <Divider light={true} /> */}

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Your DSCVR Balance</Typography>
                <Box display="flex">
                  <Typography variant="body1" style={{ paddingRight: '32px' }}>
                    {tokenBalanceEthereum}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            open={openBinance}
            onClose={() => setBinance(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            className={classes.curvedDialogue}
            PaperProps={{ style: { borderRadius: 23 } }}
          >
            <Box display="flex" p={3} pl={2} alignItems="center">
              <img
                src={BinanceLogo}
                alt="eth mannet"
                width="43px"
                style={{ marginRight: '12px' }}
              />
              <Typography variant="h6">Binance Smart Chain</Typography>
            </Box>

            <DialogContent className={classes.dialogueContent}>
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Bridge Address</Typography>
                <Box display="flex">
                  <Typography variant="body1">
                    {sortAddress(ETHswap)}
                  </Typography>
                  <Link
                    href={`https://bscscan.com/address/${ETHswap}`}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#3220ac"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                      className={`${classes.links} jss226`}
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>

              <Divider light={true} />
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Token Address</Typography>

                <Box display="flex">
                  <Typography variant="body1">
                    {sortAddress(BinanceTokenAddress)}
                  </Typography>
                  <Link
                    href={`https://bscscan.com/address/${BinanceTokenAddress}`}
                    className={classes.links}
                    target="_blank"
                  >
                    <svg
                      className={`${classes.links} jss226`}
                      // class="jss226"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#3220ac"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>
              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Total Token Supply</Typography>
                <Typography variant="body1" style={{ paddingRight: '32px' }}>
                  {totlaSupplyBinance}
                </Typography>
              </Box>
              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Total DSCVR Locked</Typography>
                <Box display="flex">
                  <Typography variant="body1" style={{ paddingRight: '32px' }}>
                    {tokenBalanceBinance && tokenBalanceBinance.lockedBalance}
                  </Typography>
                </Box>
              </Box>

              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="h6">Your DSCVR Balance</Typography>
                <Box display="flex">
                  <Typography variant="body1" style={{ paddingRight: '32px' }}>
                    {tokenBalanceBinance && tokenBalanceBinance.binanceBalance}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </>
      </Grid>
    </>
  );
};

export default Home;
