import BSCSwapAgentABI from './BSCSwapAgent_ABI.json';
import EthSwapAgentABI from './EthSwapAgent_ABI.json';

import IBEP20ABI from './IBEP20_ABI.json';
import BEP20IMPLABI from './BEP20IMPLABI.json';

import ERC20Abi from './ERC20Abi.json';

// export const TokenValue = '5000';

export const BinanceTokenAddress = '0xf30F342ACBecEb549077BDFEC015cD60ba39B8Db';
export const ETHswap = '0xDfe202020e9CB409b33082BdDA5406534276Dc71';

export const ETHTokenAddress = '0x20eF7F61975B3b676dC81011dBA28C4cC285E82f';
export const BSCswap = '0x033669D1000D5045f8fF13295e502aFF37bB0f76';

export const MaticTokenAddress = '0x3A99A3B0151457377281d41aEf46Ab2b4f263f6A';
export const Maticswap = '0x5A4C95E0aDC0263eA4FF4Aca5a2e78a1e619A6f3';

export const AdminAddress = '0xEd27E5c6CFc27b0b244c1fB6f9AE076c3eb7C10B';
export const AdminPriKey =
  'f356e73555f3f2ba4b063a34ffa795b6b7a267f9d2a9618a93d8e3cc80f16c38';
// 'b0b39c4889acdae8b50acc3cdeffdc749adfb5eccd53ef87ab1970cd14a07f84';

export const EthereumChainId = 42;
export const BinanceChainId = 97;
export const MaticChainId = 80001;

export const BSCSwapAgentABIData = BSCSwapAgentABI;
export const EthSwapAgentABIData = EthSwapAgentABI;
export const MATICSwapAgentABIData = EthSwapAgentABI;

export const IBEP20ABIData = IBEP20ABI;
export const IMATIC20ABIData = IBEP20ABI;

export const ERC20AbiData = ERC20Abi;
export const BEP20IMPLABIData = BEP20IMPLABI;

export const binanceNode = 'https://data-seed-prebsc-1-s1.binance.org:8545';
export const ethNode =
  'https://kovan.infura.io/v3/e6d9371c89484a369ca2f34df60537ea';

export const maticNode = 'https://rpc-mumbai.maticvigil.com/';
