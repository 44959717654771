import React from 'react';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
// import TwitterIcon from '@material-ui/icons/Twitter';
import { Container } from '@material-ui/core';
// import TelegramIcon from '@material-ui/icons/Telegram';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  links: {
    color: '#62B6cb',
  },

  labs: {
    color: '#61788e',
  },

  icons: {
    width: '24px',
    height: '24px',
    marginRight: '11px',
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <Box pt={8}>
      <Container maxWidth="md" className={classes.container}>
        <Box textAlign="center">
          {/* <Typography
            variant="body1"
            style={{ fontSize: 13, textAlign: 'center' }}
          >
            © 2021 ----- Network ( BVI ) LTD
          </Typography> */}
        </Box>

        <Box mt={2}>
          {/* <Link href="https://t.me/trodl" target="_blank">
            <TelegramIcon className={classes.icons} />
          </Link>

          <Link href="https://twitter.com/trodlcom" target="_blank">
            <TwitterIcon className={classes.icons} />
          </Link> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
